import { Theme } from 'theme-ui'

export const space = {
  zero: 0,
  xxs: 4,
  xs: 6,
  /**
   * 8px
   */
  sm: 8,
  /**
   * 12px
   */
  sm1: 12,
  /**
   * 16px
   */
  md: 16,
  mdn: -16,
  md1: 24,
  md1n: -24,
  md2: 32,
  md3: 40,
  l: 48,
  l1: 54,
  l2: 64,
  l3: 72,
  xl: 80,
  xl1: 90,
  xl2: 100,
  xl2n: -100,
  xl3: 120,
  gridGap1: 16,
  gridGap2: 24,
  gridGap3: 32,
  inputGap: 27,
  tabContent: [20, 0],
}
// 64px is the height of the header on desktop
// 130px is the height of the header on desktop when the menu is open
// [0,]
export const headerHeight = [64, null, 130]

const colors = {
  beige: '#FAEFD8',
  main: '#FFF',
  primary: '#11828',
  accent: '#FAEFD8',
  bg: '#F8F8F8',
  bgDarkBlue: '#041826',
  strokes: '#595858',
  txt: '#2B2B2B',
  success: '#23DC78',
  info: '#006AB6',
  infoLight: '#e7f5ff',
  warning: '#F4AD19',
  error: '#F05C5C',
  secondary: '#01182B',
  text: '#2B2828',
  textMid: '#4D4D4D',
  linkBase: '#4D4D4D',
  background: '#FFF',
  dark: '#F0F0F0',
  zambezi: '#595858',
  productBackground: '#F0F0F0',
  border: '#E6E6E6',
  borderMid: '#CCCCCC',
  mutedLight: '#999999',
  muted: '#666666',
  button: {
    text: '#FFF',
  },
  navDarkLight: '#110F0E',
  navDark: '#000',
  navLight: '#FFF',
  footer: {
    background: '#041826',
    link: '#FFF',
    heading: '#FFF',
  },
}

export const customBreakpoints = {
  max320: '@media screen and (max-width: 320px)',
}

export type BenSoleimaniTheme = Theme & {
  breakpoints: string[]
}

export const theme = {
  colors,
  fonts: {
    body:
      'Helvetica, Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    bodyAlt:
      'Roboto, Helvetica,  system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: `'Cormorant Garamond', Times New Roman, serif`,
  },
  fontWeights: {
    body: 200,
    heading: 400,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  buttons: {
    primary: {
      fontSize: '16px',
      cursor: 'pointer',
      color: 'button.text',
      bg: 'secondary',
      borderColor: 'secondary',
      textDecoration: 'none',
      letterSpacing: '.5px',
      textTransform: 'uppercase',
    },
    primarySmall: {
      variant: 'buttons.primary',
      height: 40,
      px: 16,
      borderRadius: 0,
    },
    secondary: {
      color: 'main',
      borderColor: 'strokes',
      bg: 'strokes',
      textDecoration: 'none',
    },
    tertiary: {
      color: 'button.text',
      borderColor: 'button.text',
      bg: 'transparent',
      letterSpacing: '0.5px',
    },
    tertiaryDark: {
      color: 'text',
      borderColor: 'text',
      bg: 'transparent',
    },
    noStyle: {
      cursor: 'pointer',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
      borderRadius: 0,
    },
    noStyle2: {
      variant: 'buttons.noStyle',
      width: 'auto',
      height: 'auto',
      color: 'inherit',
    },
    link: {
      color: 'text',
      borderColor: 'transparent',
      bg: 'transparent',
      textDecoration: 'underline',
    },
    xs: {
      textAlign: 'center',
      height: 'auto',
      width: 'auto',
      bg: 'primary',
      color: 'main',
      px: '8px',
      py: '4px',
      fontSize: 1,
      borderRadius: 0,
      border: 0,
      textDecoration: 'none',
    },
  },
  links: {
    primary: {
      color: 'text',
      fontSize: 4,
      fontWeight: 'normal',
    },
    bold: {
      fontWeight: 'bold',
    },
    nav: {
      color: 'inherit',
      fontWeight: 'body',
      fontSize: 2,
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    navSub: {
      color: 'linkBase',
      fontWeight: 1,
      fontSize: 3,
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    itemLink: {
      color: 'main',
      fontWeight: 'normal',
      fontSize: 14,
      textDecoration: 'none',
      pb: '11px',
    },
    footer: {
      color: 'main',
      fontWeight: 'normal',
      fontSize: 14,
      textDecoration: 'none',
      pb: '11px',
    },
  },
  images: {
    productLifestyle: {
      objectFit: 'cover',
      minHeight: '100%',
      width: '100%',
      transform: 'none !important',
    },
    productOverhead: {
      objectFit: 'contain',
      height: '100%',
    },
    productFront: {
      height: '100%',
      objectFit: 'contain',
      minWidth: ['90%', null, '100%'],
    },
  },
  variants: {
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      color: 'main',
      bg: 'rgba(0, 0, 0, 0.25)',
    },
    inputRadioCheckbox: {
      width: 22,
      height: 22,
      minWidth: 22,
      minHeight: 22,
      border: '1px solid',
      borderColor: 'border',
      mr: 16,
      mt: '7px',
      '&:focus': {
        borderColor: 'text',
      },
    },
    inputRadioCheckboxInside: {
      position: 'absolute',
      width: 10,
      height: 10,
      transform: 'translate(50%, 50%)',
      transition: '0.3s all',
    },
    mark: {
      variant: 'styles.h4',
      position: 'relative',
      mb: 20,
      pl: [null, null, null, 64],
      ':before': {
        content: '""',
        display: ['none', null, null, 'block'],
        position: 'absolute',
        left: 0,
        top: '14px',
        width: 40,
        height: '2px',
        bg: 'text',
      },
    },
    product: {
      secondary: {
        title: {
          fontFamily: 'heading',
          fontWeight: 'normal',
          fontSize: 7,
          lineHeight: '38px',
        },
      },
    },
    list: {
      none: {
        m: 0,
        p: 0,
        listStyle: 'none',
      },
      primary: {
        my: [40, null, 80],
        '> li': {
          position: 'relative',
          display: 'block',
          pl: [44, null, 56],
          mb: [24, null, 32],
          '&:before': {
            content: '""',
            position: 'absolute',
            width: 20,
            minWidth: 20,
            height: 2,
            display: 'block',
            bg: 'muted',
            mt: 16,
            mr: [24, null, 36],
            left: 0,
          },
          'h2, h3, h4, h5, h6': {
            mt: [30, null, 60],
          },
          ol: {
            li: {
              mb: 16,
            },
          },
        },
      },
      secondary: {
        ml: '25px',
        '> li': {
          fontSize: '16px',
        },
      },
    },
    table: {
      primary: {
        variant: 'text.small',
        lineHeight: '34px',
        'th, td': {
          p: 0,
        },
        th: {
          color: 'text',
          textAlign: 'left',
          fontWeight: 'body',
          pr: 40,
        },
        td: {
          color: 'muted',
          a: {
            color: 'muted',
            textDecoration: 'none',
          },
        },
      },
    },
    visuallyHidden: {
      position: 'absolute',
      opacity: 0,
      zIndex: -1,
      width: '1px',
      height: '1px',
      overflow: 'hidden',
    },
    tag: {
      bg: 'bg',
      borderRadius: '10px',
      px: space.md,
      py: space.sm,
      display: 'inline-flex',
      fontSize: 1,
      lineHeight: 'body',
    },
    centerInGrid: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstLetterToUppercase: {
      textTransform: 'lowercase',
      display: 'inline-block',
      '::first-letter': {
        textTransform: 'uppercase',
      },
    },
  },
  /**
   * Breakpoints are used to determine the width of the viewport.
   * The values are based on the width of the viewport.
   * The values are in ems.
   * The values are in ascending order.
   * The values are in ascending order.
   * The values are in ascending order.
   *
   * 640px, 768px, 992px, 1200px, 1400px
   */
  breakpoints: ['40em', '56em', '75em', '87.5em', '100em'],
  zIndices: {
    overlay: 90,
    header: 91,
    mainMenuDropdown: 92,
    dialog: 93,
    floatDialog: 94,
    floatButton: 95,
  },
  layout: {
    container: {
      maxWidth: 1490 + 24 * 2,
      px: [24, null],
    },
    cart: {
      maxWidth: 1490 + 24 * 2,
      py: [51, null, 0],
    },
    containerPrimary: {
      maxWidth: 880 + 24 * 2,
      px: [24, null],
    },
    containerSecondary: {
      maxWidth: 480 + 24 * 2,
      px: [24, null],
    },
    mid: {
      width: ['100%', null, 576],
      maxWidth: 576,
    },
    collections: {
      header: {
        textAlign: 'center',
        position: 'relative',
        top: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      headerVideo: {
        textAlign: 'left',
        position: 'relative',
        top: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        // justifyContent: 'initial',
        flexDirection: 'column',
        paddingLeft: 115,
        // paddingTop: 100,
      },
    },
    'template-default': {
      gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr'],
      gridGap: [space.gridGap2],
    },
    'template-quickship': {
      gridTemplateColumns: ['1fr 1fr', null, '1fr 1fr 1fr'],
      gridGap: [space.gridGap2],
    },
    'template-furniture': {
      gridTemplateColumns: ['1fr 1fr', null, '1fr 1fr 1fr'],
      gridGap: [space.gridGap2],
    },
    'template-inStock': {
      gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
      gridGap: [space.xs],
    },
    'template-rugs': {
      gridTemplateColumns: ['1fr', null, '1fr 1fr'],
      gridGap: 20,
    },
  },
  styles: {
    hr: {
      color: 'lightGrey',
      mt: [0, null, 20],
      mb: [0, null, 15],
    },
    root: {
      fontFamily: 'body',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      color: 'muted',
      transition: 'padding .3s',
      letterSpacing: '0.5px',
    },
    button: {
      border: 0,
      background: 'none',
    },
    h1: {
      mt: 0,
      mb: [50, null, 100],
      variant: 'text.title1',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    h2: {
      mt: 0,
      variant: 'text.title2',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    h3: {
      mt: 0,
      mb: [20, null, 50],
      variant: 'text.title3',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    h4: {
      mt: 0,
      variant: 'text.title4',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    h5: {
      mt: 0,
      variant: 'text.title5',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    h6: {
      mt: 0,
      variant: 'text.title6',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    p: {
      variant: 'text.regular',
      color: 'muted',
      mt: 0,
    },
    ul: {
      variant: 'text.regular',
      color: 'muted',
      p: 0,
      m: 0,
    },
    img: {
      maxWidth: '100%',
    },
  },
  fontSizes: [12, 14, 16, 18, 20, 26, 32, 42, 48, 64, 72, 80, 96], //11
  space,
  text: {
    default: {
      fontSize: [3],
    },
    heading: {
      color: 'text',
    },
    title1: {
      color: 'text',
      fontSize: [5, 7],
    },
    title2: {
      color: 'text',
      fontSize: [3, 6],
      fontWeight: 'heading',
    },
    title3: {
      color: 'text',
      fontSize: [4, 5],
      fontWeight: 'heading',
    },
    title4: {
      color: 'text',
      fontSize: [2, null, 3],
      lineHeight: ['40px', null, '52px'],
      fontWeight: 'heading',
    },
    title5: {
      color: 'text',
      fontSize: [5, null, 6],
      lineHeight: ['30px', null, '42px'],
    },
    title6: {
      mt: 0,
      color: 'text',
      fontSize: [4, null, 5],
      lineHeight: ['40px', null, null],
    },
    title7: {
      mt: 0,
      color: 'text',
      fontSize: [0, null, 1],
      lineHeight: '120%',
    },
    large: {
      fontFamily: 'body',
      fontSize: [4, null, '24px'],
      lineHeight: '38px',
    },
    regular: {
      fontFamily: 'body',
      color: 'balticSea',
      fontSize: [1, null, 2, 3, 3, 3],
      lineHeight: '40px',
    },
    small: {
      fontFamily: 'body',
      fontSize: [2, null, 3],
      lineHeight: ['28px', null, '34px'],
    },
    xsmall: {
      fontFamily: 'bodyAlt',
      fontSize: [1, null, 2],
      lineHeight: ['28px', null, '34px'],
    },
    micro: {
      fontFamily: 'body',
      fontSize: [0],
      lineHeight: '20px',
    },
    articleButton: {
      fontFamily: 'body',
      fontSize: 2,
      lineHeight: '18px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    button: {
      fontFamily: 'body',
      fontSize: 3,
      lineHeight: '36px',
    },
    label: {
      fontFamily: 'body',
      fontSize: ['15px', null, 2],
      lineHeight: '18px',
      textTransform: 'uppercase',
      color: 'muted',
      fontWeight: 'body',
    },
  },
  texts: {
    label: {
      fontSize: 2,
      lineHeight: 'heading',
      textTransform: 'uppercase',
      fontWeight: 'body',
      color: 'mutedLight',
    },
  },
  forms: {
    input: {
      bg: 'bg',
      height: '40px',
      border: '1px solid',
      borderColor: 'strokes',
      borderRadius: 0,
      boxShadow: 'none',
      fontSize: '18px',
      lineHeight: '36px',
      color: '#2b2b2b',
      px: '16px',
      outline: 'none',
      transition: '0.3s all',
      fontFamily: 'body',
      ':focus': {
        borderColor: '#2b2b2b',
      },
    },
    search: {
      outline: 'none',
    },
    label: {
      fontSize: [2, null, 3],
      lineHeight: '19px',
      color: 'muted',
      mb: '16px',
    },
    inputError: {
      variant: 'forms.input',
      color: 'error',
      borderColor: 'error',
      bg: '#FFFCFC',
    },
    select: {
      bg: 'bg',
      height: '64px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'strokes',
      borderRadius: 0,
      fontSize: '18px',
      lineHeight: '36px',
      color: 'text',
      px: '16px',
      outline: 'none',
      transition: '0.3s all',
      appearance: 'none',
      ':focus': {
        borderColor: 'text',
      },
      secondary: {
        border: '0',
        borderRadius: '0',
        borderBottom: '1px solid',
        height: '34px',
        fontSize: '18px',
        color: 'text',
        '+ svg': {
          display: 'none',
        },
      },
    },
  },
  messages: {
    error: {
      border: 0,
      p: 0,
      color: 'error',
      fontSize: [1],
      lineHeight: ['24px'],
      background: 'none',
    },
  },
  alerts: {
    info: {
      background: colors.infoLight,
      color: colors.info,
    },
    default: {
      background: `none`,
      color: colors.text,
    },
  },
  menu: {
    header: {
      height: headerHeight,
    },
  },
}

export default theme
